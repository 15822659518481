<template>
    <div class="fenxiang">
        <topbutton router="kuangchi"></topbutton>
        <div class="imgs">
            <div class="i_img">
                <img src="@/assets/imgsnowball/yaoqinghaoyou.png" alt="">
                <img src="@/assets/imgsnowball/fgxx.png" style="width:42px; height: auto;">
                <img src="@/assets/imgsnowball/haoyouqidonwakuang.png" alt="">
                <img src="@/assets/imgsnowball/fgxx.png" style="width:42px; height: auto;">
                <img src="@/assets/imgsnowball/fenxiangshoyi.png" alt="">
            </div>
        </div>
        <div style="opacity: 0.2;background: rgba(255, 255, 255, 1);height:1px; margin: 22px 0"></div>
        <div style="font-size: 18px;font-weight: 700;color: rgba(255, 255, 255, 1);text-align: center;"> 
            {{ $t('lang.h345') }}
        </div>

        <div style="border-radius: 16px;background: rgba(27, 31, 46, 1); "> 
            <div class="i_lon" v-if="onelist.length == 0">
                <div class="meiyou">
                    <img src="@/assets/img/zanwu.png" alt="">
                    <span>{{ $t('lang.h213') }}~</span>
                </div>
            </div>
            <div class="tab_con" v-else>
                <div style="font-size: 14px; color: #999;" class="dis">
                    <div>{{ $t('lang.h127') }}</div>
                    <div>{{ $t('lang.h312') }}</div>
                </div>
                <div class="t_ul">
                    <div class="t_li dis" v-for="item in onelist" :key="item">
                        <span style=" font-size: 14px;">{{ item.address }}</span>
                        <span style="color: rgba(153, 153, 153, 1); font-size: 14px;"
                            v-if="item.type == '0'">{{ $t('lang.h346') }}</span>
                        <span style="color: rgba(48, 255, 252, 1); font-size: 14px;" v-else>{{ $t('lang.h214') }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';
import { sm_api } from '@/api/index'
import topbutton from '@/components/topbutton.vue';
export default {
    name: '',
    components: {
        topbutton
    },
    data() {
        return {
       
            onelist: [],
        }
    },
    watch: {
        account() {
            this.init();

        },
        lang() {
            this.init();

        },

    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            if(this.account){
                this.getonelist()
            }
           
        },
        getonelist() {
            sm_api.smchildrenlist(this.account).then(res =>{
                 if(res.code == 200){
                    this.onelist = res.data
                 }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.fenxiang {
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 21px;
    padding-top: 63px;
}

.imgs {
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 15px;

    .i_img {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 42px;
            height: 42px;
        }

        .line {
            border: 1px dotted rgba(48, 255, 252, 1);
            width: 50px;
            // height: 1px;
        }
    }

}

.i_text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    font-family: "PingFang";
}

.van_tabs {
    background: none;
    margin-top: 20px;
}

/deep/.van-tabs__nav {
    background: none !important;
}

/deep/.van-tabs__line {
    background: var(--text-color) !important;
    width: 20px;
    height: 2px;
}

/deep/.van-tab {

    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
}

/deep/.van-tab--active {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
}

.i_lon {
    width: 100%;
    height: 261px;
    border-radius: 16px;
    opacity: 1;
    margin-top: 20px;
    // margin-top: 20px;

    position: relative;

    .meiyou {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        img {
            width: 82.88px;
            height: 141.7px;
            margin-bottom: 15px;
        }

        span {
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            font-weight: 700;
            font-family: "PingFang";
        }
    }
}

.tab_con {

    border-radius: 16px;
    opacity: 1;
    background: rgba(27, 31, 46, 1);


    margin-top: 20px;
    box-sizing: border-box;
    padding: 17px 20px;

    .t_ul {
        min-height: 200px;
        max-height: 400px;
        overflow-y: scroll;
        color: rgba(255, 255, 255, 1);

    }
}

.dis {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
</style>