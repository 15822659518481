export const lang = {
  swap1: '闪兑',
  swap2: '立刻开始闪兑',
  swap3: 'from',
  swap4: 'balance: ',
  swap5: '选择币种',
  swap6: 'max',
  swap7: '价格',
  swap8: '滑点容差',
  swap9: '连接钱包',
  swap10: '输入数量',
  swap11: '余额不足',
  swap12: '此交易的流动性不足',
  swap13: '选择代币',
  swap14: '授权',
  swap15: '包装',
  swap16: '解包',
  swap17: '闪兑',
  swap18: '交易收据',
  swap19: '正在等待确认',
  swap20: '正在将',
  swap21: '交换为',
  swap22: '在你的钱包种确认此交易',
  swap23: '已提交交易',
  swap24: '在BSC上查看',
  swap25: '关闭',
  swap26: '交易拒绝',
  swap27: '确认交换',
  swap28: '输出为估值,您将获得至少{inputOutMin} {symbol},或者交易将被撤回',
  swap29: '输入为估值,您将出售最多{inputInMax} {symbol},或者交易将被撤回',
  swap30: '最小获得量',
  swap31: '最大出售量',
  swap32: 'to',
  swap33: '管理代币',
  swap34: '导入代币',
  swap35: '导入',
  swap36: '管理Tokens',
  swap37: '自定义代币',
  swap38: '全部清除',
  swap39: '搜索名称或粘贴地址',
  swap40: '设置',
  swap41: '默认交易速度(GWEI)',
  swap42: '标准',
  swap43: '快速',
  swap44: '即时',
  swap46: '输入有效的滑点百分比',
  swap47: '你的交易可能会失败',
  swap48: '你的交易可能会被超前',
  swap49: '交易截止时间(分钟)',
  swap50: '估计',
  swap51: '禁用多跳',
  swap52: '最近交易',
  swap53: '最近没有交易',
  swap54: '全部清除',
  swap55: '链接钱包',
  swap56: '加入董事会',
  swap57: '捐赠',
  swap58: '权益赠送',
  swap59: '董事会成员列表',
  swap60: '邀请',
  swap61: '复制',
  swap62: '我的DAPP邀请人:',
  swap63: '邀请记录',
  swap64: '出错啦,请稍后再试',
  swap65: '功能暂未开放',
  swap66: '领取',
  swap67: '取出成功',
  swap68: '捐赠中,待确认',
  swap69: '已捐赠',
  swap70: '非白名单用户',
  swap71: '捐赠无效',
  swap72: '当前余额不足',
  swap73: '授权中,请稍等',
  swap74: '授权失败',
  swap75: '捐赠中,请稍等',
  swap76: '捐赠失败',
  swap77: '兑换',
  swap78: '流动性',
  swap79: '会员',
  swap80: '邀请',
  swap81: '权益池',
  swap82: '跨链桥',
  swap83: '池子',
  swap84: '农场',
  swap85: '官网',
  swap86: '市场',
  swap87: '兑换',
  swap88: '交易',
  swap89: '赚取',
  swap90: '确认',
  swap91: '价格影响',
  swap92: '最小获得量',
  swap93: '流动性供应商费用',
  swap94: '增加流动性',
  swap95: '增加流动性以接收 LP 代币',
  swap96: '流动性池中的份额',
  swap97: '兑换率和流动池份额',
  swap98: '您的流动性',
  swap99: '移除流动性以收回代币',
  swap100: '未找到流动性',
  swap101: '未看到您加入的流动池？',
  swap102: '查看其他LP代币',
  swap103: '已入池',
  swap104: '移除',
  swap105: '供应',
  swap106: '导入池',
  swap107: '导入现有流动性池',
  swap108: '选择代币以查找您的流动性。',
  swap109: '您在该流动性池中还没有提供流动性。',
  swap110: '您的钱包中的LP代币',
  swap111: '接收',
  swap112: '和',
  swap113: '金额',
  swap114: '你将获得',
  swap115: '输出为估值。如果价格变化超过 {slippage}%,则您的交易将被撤回',
  swap116: '已焚毁',
  swap117: '已入金',
  swap118: '确认供应',
  swap119: '汇率',
  swap120: '您是第一个流动性供应商。',
  swap121: '您添加代币的比列,将决定该流动性池的初始价格。',
  swap122: '如果您对汇率满意,请点击“供应”以进行检查。',
  swap123: '正在移除',
  swap124: '正在供应',
  swap125: '确认供应',
  swap126: '白皮书',
  swap127: '审计',
  swap128: '公告',
  swap129: '提示',
  swap130: 'PHO 1:1 兑换',
  swap131: '更多',
  swap132: '正在加载',
  swap133: '信息',
  swap134: '捐赠名额已满',
  swap135: '我的PHO',
  swap136: '钱包中的PHO',
  swap137: '可收割的PHO',
  swap138: 'PHO价格',
  swap139: 'PHO总量',
  swap140: 'PHO市值',
  swap141: 'PHO信息',
  swap142: '已赚取',
  swap143: '质押',
  swap144: '个人TVL',
  swap145: '社区TVL',
  swap146: '收割',
  swap147: '累计激励 ',
  swap148: '今日激励',
  swap149: '参与市商地址数',
  swap150: '我的邀请链接',
  swap151: '最大算力区',
  swap152: '其他算力区',
  swap153: '我的好友邀请',
  swap154: '取消',
  swap155: '修改邀请人',
  swap156: '邀请人不能为空',
  swap157: '修改成功',
  swap158: '质押LP代币',
  swap159: '赎回LP代币',
  swap160: '赎回',
  swap161: '获取',
  swap162: '当前暂无收益',
  swap163: '路由',
  swap164: '审计机构',
  swap165: '合作伙伴',
  swap166: '发行',
  swap167: '活动',
  swap168: '单币池',
  swap169: 'LP池',
  swap170: '国会',
  swap171: '卡牌',
  swap172: '盲盒',
  swap173: '游戏',
  swap174: '权益池已分配总额',
  swap175: '待领取数额',
  swap176: '我已获取数额',
  swap177: '取出',
  swap178: '超级节点',
  swap179: '权益卡已领取份额',
  swap180: '国库',
  swap181: '国库累计金额',
  swap182: '国库已分配金额',
  swap183: '国库已回购金额',
  swap184: '专家模式',
  swap185: 'D6 Swap是基于OKExChain研发的全网首个股权税收制Defi平台,逐步推出Swap、NFT、Gamefi、Lend、机枪池等板块。面世以来通过领先的技术和公平公开的市场理念,提供更加广泛的资产增值方案和前所未有的体验感,深受广大用户喜爱。',
  swap186: '一键取出',
  swap187: '价格影响过高',
  swap188: 'FAQ',
  swap189: 'NFT',
  swap190: '赚币',
  swap191: '进行中',
  swap192: '已结束',

  swap193: '说明',
  swap194: '股权权益生态卡,全球限量1680张',
  swap195: '点亮股权权益生态卡的要求及收益如下',
  swap196: '一星卡：个人地址拥有价值1000USDT流动性,团队1000USDT流动性,收益共分交易税收0.1%',
  swap197: '二星卡：个人拥有价值2000USDT流动性,团队1万USDT流动性,收益共分交易税收0.1%',
  swap198: '三星卡：个人3000USDT流动性,团队10万USDT流动性,设立工作室,收益共分交易税收0.1%',
  swap199: '四星卡：个人拥有价值4000USDT流动性,团队100万USDT流动性,设立工作室,收益共分交易税收0.1%',
  swap200: '五星卡：个人5000USDT流动性,团队1000万USDT流动性,设立工作室,收益共分交易税收0.1%',
  swap201: '备注：所有权益生态卡收益全部链上自动分配,权益卡享受交易所所有生态分红,权益卡可传承可交易可转让,满足要求自动升级,不满足要求自动降级及自动退出。',
  swap202: '权益卡',
  swap203: '发展路线图：',
  swap204: '2022年7月  2.0版上线',
  swap205: '2022年9月  开通持币多挖',
  swap206: '2022年12月 NFT交易平台上线',
  swap207: '2023年 6月  Goodbaby游戏上线',
  swap208: '2023年 9月  Goodlend上线',
  swap209: 'D6 Swap介绍：',
  swap210: '滑点：',
  swap211: '分配：',
  swap212: '买入',
  swap213: '卖出',
  swap214: '0.5% LP分红',
  swap215: '0.5% 生态权益卡分红',
  swap216: '0.25% 回流底池销毁LP',
  swap217: '0.25% 生态建设',
  swap218: '暂无奖励领取',
  swap219: '个人TVL',
  swap220: '团队TVL',
  swap221: 'LP数量',
  swap222: '钱包中的pho',
  swap223: 'GDT',
  swap224: 'GDT空投累计奖励',
  swap225: '星级用户累计奖励',
  swap226: '免手续费倒计时',
  swap227: '可取出奖励',
  swap228: 'LP挖矿奖励',
  swap229: '质押pho',
  swap230: '赎回pho',
  swap231: '目标共识',
  swap232: '已达共识',
  swap233: '已共识',
  swap234: '共识',
  swap235: '已解锁',
  swap236: '可领取',
  swap237: '社区治理',
  swap238: '联盟共识',
  swap239: '社区联盟',
  swap240: '质押代币',
  swap241: '质押中,请稍等',
  swap242: '交易挖矿',
  swap243: '昨日新增',
  swap244: '社区白名单累计奖励',
  swap245: '股权星卡累计奖励',
  swap246: 'LP挖矿累计奖励',
  swap247: '当前可领取',
  swap248: '当前可领取金额',
  swap249: '交易挖矿',
  swap250: '我的分享奖励累计',
  swap251: '我的交易奖励累计',
  swap252: '请输入您的交易哈希',
  swap253: '校验',
  swap254: '请输入您的哈希',
  swap255: '您的交易地址',
  swap256: '您将获得',
  swap257: '仅买入GDT及创建流动性的交易可获得奖励',
  swap258: '质押GDT-PHO代币',
  swap259: '赎回GDT-PHO代币',
  swap260: '全网手续费累计',
  swap261: '待领取分红',
  swap262: 'GDT联合股东',
  swap263: '社交',
  swap264: '商城',
  swap265: '精选好物',
  swap266: '优选小店',
  swap267: '即将上线',
  swap268: 'VGT联创股东',
  swap269: '质押VGT-PHO代币',
  swap270: '赎回VGT-PHO代币',
  swap271: '推荐链接',
  swap272: '请绑定领导人',

  swap273: '详细',
  swap274: '元宇宙',
  swap275: '全局',
  swap276: '交换和流动性',
  swap277: '交易、发展、赚钱、',
  swap278: '多链聚合DEX生态平台',
  swap279: '享受最低的交易费,有利可图的功能和多类型推荐计划',
  swap280: '路线图',
  swap281: '2022年7月 2.0版上线',
  swap282: '2022年9月 开通持币多挖',
  swap283: '2022年12月 NFT交易平台上线',
  swap284: '2023年6月 Goodbaby游戏上线',
  swap285: '2023年9月 Goodlend上线',
  swap286: '了解更多',
  swap287: '您已持有',
  swap288: '在',
  swap289: '交易加密数字货币',
  swap290: '质押流动性和代币',
  swap291: '赚取分红奖励',
  swap292: '发射台',
  swap293: '认证',
  swap294: '优质加密数字资产',
  swap295: '了解',
  swap296: '艺术品',
  swap297: '交易市场',
  swap298: '浏览',
  swap299: '即时兑换代币',
  swap300: '复制成功',
  swap301: '不支持复制,该浏览器不支持自动复制',
  swap302: '联创累计奖励',
  swap303: '累计分享奖励',
  swap304: '累计交易奖励',
  swap305: '资产包',
  swap306: '交易赚币',
  swap307: '可取出余额',
  swap308: '请输入交易哈希',
  swap309: '确定',
  swap310: '请输入hash值',
  swap311: '入账成功',

  swap312: '币安',
  swap313: '平台',
  swap314: 'PHO专区',
  swap315: 'GDT专区',
  swap316: 'VGT专区',
  swap317: 'PHO星级卡',
  swap318: '个人',
  swap319: '团队',
  swap320: '钱包中的',
  swap321: '选择资产包',
  swap322: '请选择',
  swap323: '日收益率',
  swap324: '收益比例',
  swap325: '到期总数',
  swap326: '购买',
  swap327: '您的钱包余额',
  swap328: '我的资产包',
  swap329: '今日总收益',
  swap330: '今日静态收益',
  swap331: '今日动态收益',
  swap332: '累计收益',
  swap333: '到期数量',
  swap334: '选择',
  swap335: '加密货币专区',
  swap336: '购买成功',
  swap337: '进入 DApp',
  swap338: '圈子',
  swap339: '与你的加密圈伙伴一起畅聊',
  swap340: '糖果盒',
  swap341: '多种优质资产空投',
  swap342: '快来免费领取',
  swap343: '众创池',
  swap344: '铸币中心',
  swap345: '社区圈子',
  swap346: '糖果盒子',
  swap347: '赚币',
  swap348: '空投',
  swap349: '分享中心',
  swap350: '联盟计划',
  swap351: "社交",
  swap352: "桥",
  swap353: "映射",
  swap354: "Water 矿池",
  swap355: "参与矿池建设,自由赚取高额激励",
  swap356: "流动性矿池",
  swap357: "单币质押池",
  swap358: "实时",
  swap359: "已完成",
  swap360: "仅限已质押",
  swap361: "排序依据",
  swap362: "搜索",
  swap363: " 热门 ",
  swap364: "搜索矿池",
  swap365: "这里什么也没有！",
  swap366: "首个Water Swap的启动模板",
  swap367: "为每个优质投资者和加密资产找到最佳匹配路径 ",
  swap368: "创建ISO",
  swap369: "成为未来的加密明星",
  swap370: "即将到来",
  swap371: "请先绑定推荐人",
  swap372: "我的邀请人",
  swap373: "邀请人地址：",
  swap374: "",
  swap375: "Q3  推出并上线D6 Swap,并开启创世铸币,同时引入加密社交平台CIRCLE,以深度链接社区生态发展。",
  swap376: "Q4  对D6进行流动性注入,开启 “D6共识计划”,打造D6流通共识,并启用流动性激励计划。推出链上联盟跨境商城,打造“消费增值”生态,推动社区与商业结合新方向。",
  swap377: "",
  swap378: "Q1 一揽子联盟扶持计划,推动D6作为基础流动性支持,提高市场认可和占比度,上线D-Pay,促进D6 Coin的实体交易流通",
  swap379: "Q2 正式上线D6 Trade,开启CEX和DEX双交易平台时代,进一步提高D6市场交易流动性,并引入新的伙伴。",
  swap380: "",
  swap381: "",
  d1: "充币",
  d2: "提币",
  d3: "转账",
  d4: "账单",
  d5: "社区",
  d6: "闪兑",
  d7: "问题反馈",
  d8: "铸造",
  d9: "赚取D6 Coin",
  d10: "收益高达",
  d11: "进入",
  d12: "Water行情",
  d13: "今日价格",
  d14: "今日收益",
  d15: "查看",
  d16: "资产",
  d17: "隐藏零资产",
  d18: "币种",
  d19: "冻结",
  d20: "可用",
  d21: "选择通证",
  d22: "充值数量",
  d23: "请输入充值数量",
  d24: "钱包余额:",
  d25: "入账地址",
  d26: "确定",
  d27: "充值记录",
  d28: "提取数量",
  d29: "请输入提取数量",
  d30: "账户余额：",
  d31: "到账地址",
  d32: "提币记录",
  d33: "转账数量",
  d34: "请输入转账数量",
  d35: "输入对方地址",
  d36: "请输入对方地址",
  d37: "转账记录",
  d38: "资产兑换",
  d39: "支付",
  d40: "得到",
  d41: "数量",
  d42: "全部",
  d43: "最多可兑换：",
  d44: "交易税：",
  d45: "应到账：",
  d46: "支付数量：",
  d47: "闪兑记录",
  d48: "确认兑换",
  d49: "闪兑记录",
  d50: "已收益",
  d51: "待收益",
  d52: "燃烧金额",
  d53: "请输入燃烧金额",
  d54: "进行中",
  d55: "已结束",
  d56: "流通进度",
  d57: "暂无记录！",
  d58: "历史收益",
  d59: "我的等级",
  d60: "当前等级",
  d61: "注册后即可成为 D0用户",
  d62: "好友邀请计划",
  d63: "邀请好友共同铸币,开启Water共识革命,加速赚取奖励！",
  d64: "复制链接",
  d65: "我的推荐人",
  d66: "我的有效推荐",
  d67: "地址",
  d68: "级别",
  d69: "业绩",
  d70: "下一等级：",
  d71: "市场业绩",
  d72: "尚未完成",
  d73: "市场部门",
  d74: "您已是最高级别",
  d75: "公告",
  d76: "绑定确认",
  d77: "领导人地址：",
  d78: "确认绑定",
  d79: "不能为负和零",
  d80: "余额不足",
  d81: "充币成功",
  d82: "充币失败",
  d83: "手续费：",
  d84: "转入",
  d85: "转出",
  d86: "账单记录",
  d87: "时间",
  d88: "类型",
  d89: "数量",
  d90: "币种",
  d91: "请先选择支付通证",
  d92: "此通证禁止双向兑换",
  d93: "不能选择相同的通证",
  d94: "请先绑定领导人",
  d95: "剩余可燃烧金额",
  d96: "请先绑定推荐人",
  d97: "请填写推荐人邀请码",
  d98: "最新公告",
  d99: "注：",
  d100: "2:提币订单,预计为T+1日确认",
  d101: "1:单笔提币 {min_withdraw} {token_name} 起提",
  d102: "2:每笔提币最少收取{min_fee} {token_name}税收",
  d103: "团队地址",
  d104: "团队有效",
  d105: "今日充值",
  d106: "今日提取",
  d107: "个人",
  d108: "团队",
  d109: "开启后,达到铸造条件,自动参与铸造",
  d110: "充值后预计1-2分钟到账",
  d111: "每笔转账收取{feerate}%交易税",
  d112: "如有疑问,请遗留问题反馈！",
  d113: "反馈问题",
  d114: "反馈列表",
  d115: "反馈内容",
  d116: "线上客服",
  d117: "暂未回复",
  d118: "回复：",
  d119: "1.意见反馈",
  d120: "2.添加图片(最多3张)",
  d121: "提交",
  d122: "请上传png或jpg格式图片",
  d123: "上传中,请稍候...",
  d124: "文件大小不能超过 10M",
  d125: "用户",
  d126: "CIRCLES  AirDrop 矿池",
  d127: "发行总量",
  d128: "当前产出",
  d129: "提取",
  d130: "我的余额",
  d131: "查看",
  d132: "分享人数",
  d133: "工作的矿机",
  d134: "时",
  d135: "分",
  d136: "秒",
  d137: "挖矿中",
  d138: "启动挖矿",
  d139: "邀请好友",
  d140: "好友启动挖矿",
  d141: "获得分享收益",
  d142: "一级分享",
  d143: "地址",
  d144: "状态",
  d145: "没有更多了",
  d146: "二级分享",
  d147: "创世挖矿",
  d148: "进入",
  d149: "CIRCLES致力于加密社交隐私公链建设,计划将于2025年推出,公链代币总量20亿枚,每个用户可领取1T算力,逐量衰减,累计免费挖出80%即止。",
  d150: "总资产估值",
  d151: "9·18 通证",
  d152: "9·18通证是WaterSwap合作的首个以历史题材社区通证,以“人人持有、永世不忘”为目标,铭记历史,杜绝淡化,从9·18通证开始 ！即刻免费领取吧！",
  d153: "挖矿规则",
  d154: "每个用户可获得1T算力,每T算力初始24小时产出30枚,每产出1000万枚算力递减20%。",
  d155: "直接邀请50%,间接邀请25%算力加成激励！",
  d156: "领取空投",
  d157: "提币尚未开放",
  d158: "当您绑定市场领袖后,即可领取9.18枚918通证空投。",
  d159: "若您参与分享,每分享10个用户领取空投,您将再获得一次空投奖励资格,最高可获得918枚！",
  d160: "我的空投",
  d161: "普惠空投",
  d162: "我的分享",
  d163: "提币尚未开放",
  d164: "当前等级",
  d165: "查看收益",
  d166: "普通用户",
  d167: "VIP会员",
  d168: "升级",
  d169: "复制邀请码",
  d170: "地址",
  d171: "状态",
  d172: "生态",
  d173: "持有卡牌",
  d174: "参与生态建设,获得丰厚奖励",
  d175: "今日可领取",
  d176: "C2C交易市场",
  d177: "当前等级",
  d178: "领取",
  d179: "进入",
  d180: "您没有邀请码",
  d181: "我的市场",
  d182: "不能购买",
  d183: "您的领袖未成为会员",
  d184: "链上余额不足",
  d185: "平台余额不足",
  d186: "签名失败",
  d187: "转账失败",
  d188: "续费",
  d189: "邀请码",
  d190: "你需支付",
  d191: "钱包余额",
  d192: "余额",
  d193: "平台余额",
  d194: "支付中...请等待",
  d195: "支付成功",
  d196: "确定支付",
  d197: "关闭",
  d198: "支付失败",
  d199: "交易",
  d200: "赚币",
  d201: "空投",
  d202: "社区",
  d203: "生态中心",
  d204: '选择网络',
  d205: "枚",
  d206: "已领取",
  d207: "未领取",

  h1: '首页',
  h2: '矿池',
  h3: '交易',
  h4: '发射台',
  h5: '伙伴',
  h6: '成为Happy Dex合伙人',
  h7: '畅享八大生态权益，快来参与吧！',
  h8: '进入',
  h9: '币种',
  h10: '可用额',
  h11: '冻结额',
  h12: '在Happy Dex上',
  h13: '交易加密数字货币',
  h14: '参与规则',
  h15: '招募规则',
  h16: 'Happy DEX 合伙人招募,招募 200名创世合伙人,分3期开展,相关规则如下:',
  h17: '招募条件：',
  h18: '招募期支付',
  h19: '获得规则：',
  h20: '1：分享5个有效合伙人自动升级为创世合伙人，先到先得，计划200席位创世合伙人：先发行一个HAPC代币，上线前把 HAPC 代币映射为HAP',
  h21: '第一期30个，空投16个/人；',
  h22: '第二期60个，空投11个/人；',
  h23: '第三期110个，空投6个/人。',
  h24: '如果形势好，可以出公告，增加第四期N个（计划），空投n个/人',
  h25: '2：合伙人推荐合伙人可享受20%的邀请奖励',
  h26: '3：创世合伙人邀请合伙人可获得30%邀请激励 + 10%团队无限代邀请激励（平级除外）',
  h27: '4、无合伙人邀请链接，不能操作充值成为合伙人，也不能推荐合伙人',
  h28: '5、系统后端增设：创世合伙人、合伙人的开关设置。',
  h29: '合伙人招募计划',
  h30: '第一期',
  h31: '第{round}期',
  h32: '第三期',
  h33: '招募进度',
  h34: '开始时间：',
  h35: '结束时间：',
  h36: '招募席位：',
  h37: '席位费用：',
  h38: '账户余额',
  h39: '加入',
  h40: '不可加入',
  h41: '最新合伙人',
  h42: '地址',
  h43: '支付金额',
  h44: '状态',
  h45: '已完成',
  h46: '签名失败',
  h47: 'HappyDEX矿池',
  h48: '在Happy Dex矿池,自由赚取高额激励',
  h49: '首个HappyDEX的启动模板',
  h50: '暂无信息~',
  h51: '全新上线',
  h52: '交易就来Happy DEX',
  h53: '高效率 多收益',
  h54: '跨桥链',
  h55: '普通用户',
  h56: '合伙人',
  h57: '创世合伙人',
  h58: '问题反馈',
  h59: '空投活动',
  h60: 'NFT 中心',
  h61: '生态AI',
  h62: '铭文铸造',
  h63: '合伙人权益',
  h64: '1: 成为合伙人必须上级也是合伙人',
  h65: '2: 合伙人推荐合伙人可以获得20%推荐奖励',
  h66: '3: 直接分享5个合伙人,可自动升级为“创世合伙人”,最多200个席位,结束为止。',
  h67: '4: 创世合伙人推荐合伙人可以获得30%推荐奖励+ 10%无限代市场奖励（平级则无）。 ',
  h68: '5: 创世合伙人可以获得HAPB并在上线前映射为HAP,规则如下: ',
  h69: '第一期30个席位,空投15 / 席；',
  h70: '第二期60个席位,空投10 / 席；',
  h71: '第三期110个席位,空投5 / 席。',
  h72: '6: 合伙人每日可获得10枚HAPC空投权益。',
  h73: '温馨提示:',
  h74: '1: 合伙人席位有限,仅招募期间可参与。',
  h75: '2: 抢购席位前,需确保您的账户存在足够的余额,需转入账户资产。',
  h76: '招募介绍',
  h77: 'Happyswap是一家去中心化现货合约交易所,为用户提供安全、高效、的交易体验。消除价格影响,打破原有的平台垄断和操控。用户可以在HappySwap平台进行多种数字资产交易和其他数字资产衍生品交易。HappySwap合伙人全球限量发行,作为合伙人不光可以享受八大合伙人权益,整个平台的盈利跟所有合伙人都有着紧密的关系。数字资产新时代,共同打造真正去中心化交易新玩法,共享数字金融万倍神话。',
  h78: '剩余席位',
  h79: '合伙人',
  h80: '创世',
  h81: '普通',
  h82: '合伙',
  h83: '本期未开启',
  h84: '加入',
  h85: '本期已招满',
  h86: '本期已结束',
  h87: '投票时间',
  h88: '赞成',
  h89: '反对',
  h90: '已投票',
  h91: '即时闪兑代币',
  h92: '发送',
  h93: '余额:',
  h94: '币安智能链',
  h95: '接收（预估）',
  h96: '最少接收数量',
  h97: '兑换路径',
  h98: '选择通证获取入账地址',
  h99: '最低充值2HAP起',
  h100: '持有 NFT打造财富新机遇',
  h101: '待产出算力',
  h102: '今日算力收益',
  h103: '今日分享收益',
  h104: '账单',
  h105: '查看',
  h106: '个人有效能量',
  h107: '最大社区能量',
  h108: '其他社区能量',
  h109: '龙虎榜',
  h110: '能量值：',
  h111: '算力值：',
  h112: '兑换',
  h113: '未开放',
  h114: '已产出：',
  h115: ' 待产出：',
  h116: '产出进度',
  h117: '你需支付',
  h118: '账户余额',
  h119: '余额',
  h120: '确定支付',
  h121: 'NFT商店',
  h122: '产出中',
  h123: '已耗尽',
  h124: '邀请好友',
  h125: '购买NFT',
  h126: '获得分享收益',
  h127: '地址',
  h128: '个人能量',
  h129: '团队能量',
  h130: '算力收益',
  h131: '已领取',
  h132: '待领取',
  h133: '领取',
  h134: '分享收益',
  h135: '龙虎榜',
  h136: '时间',
  h137: '数量',
  h138: '币种',
  h139: '算力账单',
  h140: '分享账单',
  h141: '龙虎榜账单',
  h142: '兑换时间',
  h143: '产出中',
  h144: '已耗尽',
  h145: 'NFT中心',
  h146: '游戏市场',
  h147: '欢迎加入',
  h148: 'GOC链游生态市场',
  h149: '【Goldfinger】GOC致力于打造全球Web3.0生态系统，为用户提供完善的去中心化金融平台，通过Web3.0+AI＋链游等多方面结合，打造全新一代Web3.0去中化生态。GOC大生态将上线百款链游，为玩家带来游戏与金融的新体验，带来多元化的赚钱机会，让玩家通过代币赚取更多收益，更加促进GOC的流通和进一步发展。随着GOC应用生态的不断上线，GOC将会带来无限的价值体现。GOC平台让玩web3游戏变得无缝且轻松。',
  h150: '参与游戏建设',
  h151: '即将到来',
  h152: '合作伙伴',
  h153: '去购买',
  h154: '生态系统',
  h155: '欢迎体验',
  h156: '社交游戏',
  h157: 'GOC专注web3.0Ai链游赛道，在元宇宙的世界里，社区经济将成为主流，社区的协作共识成为新的增长点，跟你的团队一起协作吧！在激烈、快节奏、混乱的战斗中脱颖而出，升级赢得财富宝箱，赢得最终胜利！',
  h158: '更多游戏',
  h159: 'HappyDex游戏市场将保持开放理念，欢迎第三方游戏平台一起合作研发上架，打造更具有颠覆性的链游生态，吸引全球玩家，成为链游领域最具有代表的平台， GOC将会为玩家带来链上游戏与金融新体验...',
  h160: '敬请期待',
  h161: '游戏账户',
  h162: '资产账户',
  h163: '数量',
  h164: '请输入数量',
  h165: '全部',
  h166: '最多可划转',
  h167: '应到数量',
  h168: '划转',
  h169: '账单',
  h170: '时间',
  h171: '数量',
  h172: '状态',
  h173: '待入账',
  h174: '暂无记录',
  h175: '划转已关闭',
  h176: '划转数量不在范围内',
  h177: '已完成',
  h178: '转出',
  h179: '转入',
  h180: '余额不足',
  h181: 'GameFi市场',
  h182: '参与GOC链游生态，一起赚取丰厚奖励！',

  h183: '会员招募',
  h184: '即将开启',
  h185: 'BTC比特币挖矿每四年减产一次，一直挖矿到2140年。HappyNeiro的问世，是为了追随比特币，致敬中本聪！它继承了 $DOGE 的精神，HappyNeiro 即将在 Binance Smart Chain 上掀起新的狂潮！让我们共同期待并见证HappyNeiro代币的辉煌时刻！',
  h186: '招募进行中',
  h187: '招募结束',
  h188: '加入',
  h189: '您已加入',

  h190: '1、优先购买的白名单资格。',
  h191: '您将获得',
  h192: '2、HappyNeiro代币空投礼包。 （上线后24小时内空投到白名单地址）',
  h193: '礼包规则：',
  h194: '1～2000名 ： {rate} 份空投礼包',
  h195: '2001～4000名： {rate}份空投礼包',
  h196: '4001及以上： {rate}份空投礼包',
  h197: '3、每直接分享一人参与可以获得{rate}份空投， 上不封顶。',
  h198: '您已获得',
  h199: '参与空投礼',
  h200: '直推空投礼',
  h201: '份',
  h202: '领取',
  h203: '获得代币',
  h204: '确定',
  h205: '恭喜您，成功加入',
  h206: '待确认',
  h207: 'V2全新推出',
  h208: '提取',
  h209: '获得代币',
  h210: '查看',
  h211: '分享人数',
  h212: '好友参与招募',
  h213: '暂无信息',
  h214: '已参与',
  h215: '节点',
  h216: '获得份数奖励',

  h217: '进行中',
  h218: '未开启',
  h219: '已赚取',
  h220: '领取',
  h221: '不可领取',
  h222: '收益中',
  h223: '质押中',
  h224: '到期日',
  h225: '已赎回',
  h226: '赎回',
  h227: '账户余额',
  h228: '参与数量',
  h229: '全部',
  h230: '单次可新增数量为',
  h231: '起',
  h232: '确定',
  h233: '操作成功',
  h234: '未开启',
  h235: '进行中',
  h236: '已结束',
  h237: '签名失败',
  h238: '参与成功',
  h239: '赎回成功',
  h240: '暂无可领取',
  h241: '升级成功',
  h243: '说明： 升级成为基金会联合股东，并享受基金会权益，每份20HAP，其中50％将进行销毁，50％质押365天，您可认购多份基金会份额。',
  h244: '不可购买',
  h245: '流动激励',
  h246: '累计发放',
  h247: '今日发放',
  h248: '去流动',
  h249: '分享激励',
  h250: '基金会激励',
  h251: '去查看',
  h252: '今日激励',
  h253: 'HAP激励',
  h254: 'GOC激励',
  h255: '查看',
  h256: '有效分享',
  h257: '社区战队',
  h258: '流动仓',
  h259: '可流动',
  h260: '待流动',
  h261: '流动中',
  h262: '激励仓',
  h263: '可售出',
  h264: '待售出',
  h265: '流动市场',
  h266: '激励市场',
  h267: '我的订单',
  h268: '发布时间',
  h269: '流动币种',
  h270: '售出币种',
  h271: '流动数量',
  h272: '售出数量',
  h273: '流动周期',
  h274: '流动收益',
  h275: '暂无订单~',
  h276: '参与流动',
  h277: '售出激励',
  h278: '发起流动',
  h279: '发起数量',
  h280: '可挂单数量',
  h281: '到期收益',
  h282: '需要燃料',
  h283: '余额',
  h284: '说明：确保您的余额足够，订单一旦提交，GOC燃料即消耗，无法退还，订单成交后，开始计算收益。',
  h285: '售出激励',
  h286: '售出数量',
  h287: '可售出总额',
  h288: '说明：当您发起售出后，将根据协议自动匹配并完成订单。',
  h289: '确定',
  h290: '流动单',
  h291: '售出单',
  h292: '撤回',
  h293: '待完成',
  h294: '已完成',
  h295: '已撤回',
  h296: '发起数量',
  h297: '售出数量',
  h298: '匹配时间',
  h299: '完成时间',
  h300: '激励数量',
  h301: '可撤数量',
  h302: '发布时间',
  h303: '撤回时间',
  h304: '暂无订单~',
  h305: '排队中',
  h306: '已匹配',
  h307: '已完成',
  h308: '已撤回',
  h309: '好友参与协议',
  h310: '获得分享激励',
  h311: '我的分享',
  h312: '状态',
  h313: '成员',
  h314: '用户',
  h315: '暂无信息~',
  h316: '可赎回',
  h317: '领取成功',
  h318: 'HAP流动协议',
  h319: '参与HAP流动 共建HAP大生态',
  h320: '升级',
  h321: '已升级',
  h322: '可输入出售数量',
  h323: '等待开放',
  h324: '份',
  h325: '选择份数',
  h326: '输入份数',
  h327: '您需支付',
  h328: '不可发布',
  h329: '未绑定上级',
  h330: '不可发布流动单',
  h331: '不可发布激励单',

  h332: '最大分享区',
  h333: '其他分享区',
  h334: '团队有效',

  h335: '正在进行',
  h336: '已经结束',
  h337: '说明',
  h338: '1 ：支持USDT或HAP进行支付',
  h339: '2： HAP支付享受的{rate}%折扣标准',
  h340: '3：合伙人支付享受{rate}%的折扣标准',
  h341: '4：基金会支付享受{rate}%的折扣标准',
  h342: '5：用户只可享受最高的折扣标准，不累加。',
  h343: '6：Btpi上线后持有价值大于{rate}U的LP即可获得原始代币空投',
  h344: '您已加入',
  h345: '我的分享',
  h346: '未参与',
  h347: '股东招募',
  h348: 'Pi在全球200多个国家/地区拥有超过6000万活跃用户，接近2亿注册用户，Pi的推特粉丝数量超过338万几乎赶上了以太坊。从未见过一个加密项目拥有其他加密项目无法比拟的活跃社区。致敬BTC，比特派将比特币的颠覆性力量与Pi的无限精神结合，开启数字世界的无限未来，这不仅仅是一个区块链项目，更是一场引领全球参与的创新革命。',
  h349: '创世募集',
  h350:"禁止加入",
  h351:"直推HAP份数",
  h352:"团队HAP份数",
  h353:"直推USDT份数",
  h354:"团队USDT份数",
};
