<template>
    <div class="recuitMember">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #fff" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
            <div></div>
        </div>
        <div class="Box">
            <div class="BoxItem">
                <div class="BoxItem_absText">{{ $t('lang.h208') }}</div>
                <img src="@/assets/img/recuititem_icon1.png" class="BoxItem_absImg" alt="">
                <div class="BoxItem_title">{{ $t('lang.h209') }}</div>
                <div class="BoxItem_num">{{ summaryData.token_amount }}</div>
            </div>
            <div class="BoxItem">
                <div class="BoxItem_absText" @click="gochildlist">{{ $t('lang.h210') }}</div>
                <img src="@/assets/img/recuititem_icon2.png" class="BoxItem_absImg" alt="">
                <div class="BoxItem_title">{{ $t('lang.h211') }}</div>
                <div class="BoxItem_num">{{ summaryData.share_num }}</div>
            </div>
        </div>
        <div class="copiesBox">
            <div class="copiesBox_line" style="margin-bottom: 21px;">
                <div class="copiesBox_line_box">
                    <span class="copiesBox_line_box_title">{{ $t('lang.h351') }}</span>
                    <span class="copiesBox_line_box_num">{{ summaryData.hap_dr_num }}</span>
                </div>
                <div class="copiesBox_line_box">
                    <span class="copiesBox_line_box_title">{{ $t('lang.h352') }}</span>
                    <span class="copiesBox_line_box_num">{{ summaryData.hap_team_num }}</span>
                </div>
            </div>
            <div class="copiesBox_line">
                <div class="copiesBox_line_box">
                    <span class="copiesBox_line_box_title">{{ $t('lang.h353') }}</span>
                    <span class="copiesBox_line_box_num">{{ summaryData.u_dr_num }}</span>
                </div>
                <div class="copiesBox_line_box">
                    <span class="copiesBox_line_box_title">{{ $t('lang.h354') }}</span>
                    <span class="copiesBox_line_box_num">{{ summaryData.u_team_num }}</span>
                </div>
            </div>
        </div>
        <div class="Boxlevel" style="">
            <img src="@/assets/img/recruit_logo.png" alt="">
            <span>{{ $t('lang.h349') }} </span>
            <span style="margin: 0;">${{ smConfigData.pay_amount }}</span>
        </div>
        <div class="Boxlevel" style="background: rgba(27, 31, 46, 1);">
            <div class="Boxlevel_box">
                <div class="Boxlevel_box_title">
                    {{ $t('lang.h337') }}
                </div>
                <div class="Boxlevel_box_text">
                    <p> {{ $t('lang.h338') }}</p>
                    <p>{{ $t('lang.h339', { rate: smConfigData.hap_discount * 100 }) }}</p>
                    <p>{{ $t('lang.h340', { rate: smConfigData.partner_discount * 100 }) }}</p>
                    <p>{{ $t('lang.h341', { rate: smConfigData.foundation_discount * 100 }) }}</p>
                    <p>{{ $t('lang.h342') }}</p>
                    <p>{{ $t('lang.h343', { rate: 100 }) }}</p>
                </div>
            </div>
            
            <div class="Boxlevel_button" v-if="smConfigData.status == '5'"
                style="border: 1px solid rgba(79, 87, 117, 1);color: rgba(255, 255, 255, 1); background:rgba(13, 8, 26, 1);">
                {{ $t('lang.h344') }}
            </div>
            <div class="Boxlevel_button" @click="OpenPay" v-else>
                {{ $t('lang.h188') }}
            </div>

        </div>
        <van-popup v-model="payshow" position="bottom" round closeable>
            <div class="paypop">
                <div class="title">
                    {{ $t('lang.h117') }}
                </div>
                <div class="paypop_cont">
                    <div class="paypop_checks">
                        <div class="paypop_checks_box" @click="checkdataFun('0')">
                            <span>≈ {{ significantDigits(smConfigData.hap_pay_amount * hap_discount) }} HAP</span>
                            <div class="checkbox">
                                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '0'">
                                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
                            </div>
                        </div>
                        <div class="paypop_checks_box" @click="checkdataFun('1')">
                            <span>≈ {{ significantDigits(smConfigData.pay_amount * usdt_discount) }} USDT</span>
                            <div class="checkbox">
                                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '1'">
                                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
                            </div>
                        </div>
                    </div>
                    <div class="banlace">
                        <img src="@/assets/imgsnowball/banlce.png" alt="">
                        <div class="banlace_box">
                            <div
                                style="font-size: 16px; color: rgba(0, 0, 0, 1); font-weight: 700; margin-bottom: 7px;">
                                {{ $t('lang.h118') }}</div>
                            <div style="display: flex;">
                                <div style="width: 80px;">HAP{{ $t('lang.h119') }}：</div> {{ HAPBanlace }}
                            </div>
                            <div style="display: flex;">
                                <div style="width: 80px;">USDT{{ $t('lang.h119') }}：</div>{{ USDTBanlace }}
                            </div>
                        </div>
                    </div>
                    <div class="button" @click="paybuyFun" v-loading="payLoading"> {{ $t('lang.h204') }}</div>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="paystatus" round closeable>
            <div class="paystatus">
                <img src="@/assets/imgsnowball/paystatus_success.png" alt="">
                <div class="paystatus_text">{{ $t('lang.h205') }}</div>

            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { happydex, web3_api } from '@/api/index';
import { significantDigits } from '@/utils/format.js';
import { sm_api } from '../../api';
export default {
    data() {
        return {

            payLoading: false,
            paystatus: false,
            checkdata: '0',
            payshow: false,
            smConfigData: {
                pay_amount: 1,
                hap_pay_amount:1,
                status: '1'
            },
            summaryData: {
                dr_num: 0
            },
            USDTBanlace: 0,
            HAPBanlace: 0,
            HAPPrice: 0,
            hap_discount:0,
            usdt_discount:0,
        }
    },
    components: {

    },
    watch: {
        account() {
            this.init()
        }
    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    created() {
        this.init()
    },
    methods: {
        significantDigits,
        gochildlist() {
            this.$router.push({ name: 'childlist' })
        },
        init() {
            if (this.account) {
                Promise.all([
                    this.smconfig(),
                    this.smsummary(),
                    this.smpaydiscount()
                ])
            }
        },
        smpaydiscount(){
          sm_api.smpaydiscount({pay_type:0},this.account).then(res =>{
            this.usdt_discount = res.data.discount
          })
          sm_api.smpaydiscount({pay_type:1},this.account).then(res =>{
                       this.hap_discount = res.data.discount
          })
        },
 
        walletinfobykey() {
            happydex.walletinfobykey({ token_key: 'HAPB' }, this.account).then(res => {
                if (res.code == 200) {
                    this.HAPPrice = res.data.usd_price
                    this.HAPBanlace = res.data.usable
                    this.smConfigData.hap_pay_amount = significantDigits(this.smConfigData.pay_amount / this.HAPPrice)
                }
            })
            happydex.walletinfobykey({ token_key: 'USDT' }, this.account).then(res => {
                if (res.code == 200) {
                    this.USDTBanlace = res.data.usable
                }
            })
        },

        async getHapPrice() {
            let price = await web3_api.getCurrentPairPrice(['0xd8f0e57b3c77725cb861b3c45e2ad22894d0a2f8'], '0xb61E1493cb2cBBf2e3e89872C6095AA000D5DB32', 18, 18)

        },
        smsummary() {
            sm_api.smsummary(this.account).then(res => {
                if (res.code == 200) {
                    this.summaryData = res.data
                }
            })
        },
        smconfig() {
            sm_api.smconfig(this.account).then(res => {
                if (res.code == 200) {
                    this.smConfigData = res.data
                    this.walletinfobykey()
                }
            })
        },
        getERC20TokenBalance() {
            web3_api.getERC20TokenBalance(this.smConfigData.usdt_contract, this.account).then(res => {
                this.USDTBanlace = res
            })
            web3_api.getERC20TokenBalance(this.smConfigData.hap_contract, this.account).then(res => {
                this.HAPBanlace = res
            })
        },
        ipoentrymark(hash) {
            happydex.ipoentrymark({ tx_hash: hash }, this.account).then(res => {
                if (res.code == 200) {
                    this.paystatus = true
                    this.init()
                }
            })
        },
        toprouter() {
            this.$router.go(-1);

        },
        async paybuyFun() {
            this.payLoading = true
            if (!this.payLoading) return;
            let sign = null;
            try {
                sign = await web3_api.signMessage('SM Join', this.account)
            } catch (error) {
                this.payLoading = false
                this.$toast(this.$t('lang.d186'))
                return;
            }
            let data = {
                signature: sign,
                pay_type: this.checkdata == '1' ? 0 : 1,
            }
            sm_api.smjoin(data, this.account).then(res => {
                this.payLoading = false
                this.$toast(res.msg)
                if (res.code == 200) {
                    this.paystatus = true
                    this.init()
                }
            })
        },
        paystatusFun() {

            this.paystatus = false
        },
        checkdataFun(type) {
            this.checkdata = type
        },
        OpenPay() {
            if(this.smConfigData.status == '4') this.$toast(this.$t('lang.h329'));
            if(this.smConfigData.status == '0') this.$toast(this.$t('lang.h350'));
            if (this.smConfigData.status != '1' && this.smConfigData.status != '') return;
            this.payshow = true
        }
    },
}

</script>
<style scoped lang='less'>
.recuitMember {
    padding: 18px;

    padding-top: 60px;
    padding-bottom: 129px;
}

.recruit_title {
    color: var(rgba(255, 255, 255, 1));
    padding: 20px 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copiesBox{
    padding: 16px 21px;
    margin-bottom: 21px;
    width: 100%;
    border-radius: 21px;
    background: #191D29;
    border: 1px solid #242834;
    .copiesBox_line{
        display: flex;
        justify-content: space-between;
        .copiesBox_line_box{
            display: flex;
            flex-direction: column;
            width: 49%;
            .copiesBox_line_box_title{
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 500;
                color: #96A6B3;
            }
            .copiesBox_line_box_num{
              font-size: 14px;
              font-weight: bold;
              color: #FFFFFF;
            }
        }
    }
}

.Box {
    margin-top: 60px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .BoxItem {
        position: relative;
        width: 48%;
        ;
        padding-bottom: 16px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(25, 29, 41, 1);

        border: 1px solid rgba(36, 40, 52, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 43px;

        .BoxItem_absText {
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            position: absolute;
            right: 8px;
            top: 8px;
        }

        .BoxItem_absImg {
            width: 83px;
            height: 78px;
            position: absolute;
            left: 50%;
            top: -40%;
            transform: translateX(-50%);
        }

        .BoxItem_title {
            color: rgba(248, 251, 255, 1);
            margin-bottom: 6px;
        }

        .BoxItem_num {
            font-size: 20px;
            font-weight: 500;
            color: rgba(48, 255, 252, 1);
        }
    }
}

.Boxlevel {
    margin-bottom: 16px;
    padding: 26px 17px 8px 17px;
    width: 100%;
    opacity: 1;
    border-radius: 20px;
    background: rgba(27, 31, 46, 1);
    background: url('~@/assets/img/Box_level_bg.png') no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(255, 214, 109, 1);
    font-size: 16px;
    font-weight: 700;

    span {
        margin: 8px;
    }

    img {
        width: 105px;
        height: 105px;
    }

    .Boxlevel_button {
        margin-top: 25px;
        color: rgba(0, 0, 0, 1);
        margin-bottom: 31px;
        width: 100%;
        height: 44px;
        border-radius: 10px;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        box-shadow: 0px 10px 20px rgba(102, 102, 102, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Boxlevel_box {
        margin-bottom: 12px;
        width: 100%;
        padding: 18px;
        border-radius: 7.05px;
        background: rgba(45, 58, 82, 1);

        .Boxlevel_box_line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .Boxlevel_box_line_button {
                padding: 7px 12px;
                border-radius: 10px;
                background: rgba(109, 118, 135, 1);
                font-size: 12px;
                color: rgba(255, 255, 255, 1);
            }

            .Boxlevel_box_line_item {
                font-size: 12px;
                font-weight: 700;

                .Boxlevel_box_line_item_title {
                    margin-bottom: 11px;
                    color: rgba(255, 255, 255, 1);
                }

                .Boxlevel_box_line_item_num {
                    color: rgba(51, 252, 252, 1);
                    text-align: center;
                }
            }
        }

        .Boxlevel_box_title {
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 700;
            color: rgba(255, 255, 255, 0.6);
            text-align: center;

        }

        .Boxlevel_box_text {
            font-size: 14px;
            font-weight: 700;
            color: rgba(255, 255, 255, 1);
        }
    }
}

.paystatus {
    width: 335px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 238px;
    opacity: 1;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);

    img {
        width: 60px;
        height: 60px;
    }

    .paystatus_button {
        border: none;
        width: 100%;
        border-radius: 110px;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        font-size: 16px;
        font-weight: 700;
        color: #000 !important;
    }

    .paystatus_text {

        margin-top: 18px;
        font-size: 18px;
        font-weight: 700;
        color: rgba(34, 34, 34, 1);
    }
}

.paypop {
    width: 100%;

    .title {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        padding: 19px 20px;
        color: rgba(34, 34, 34, 1);
        border-bottom: 1px solid rgba(242, 242, 242, 1);
    }

    .paypop_cont {
        padding: 40px 21px;

        .button {
            margin-top: 23px;
            height: 48px;
            opacity: 1;
            border-radius: 110px;
            background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 700;
            color: #000;
        }

        .banlace {
            padding-bottom: 7px;
            border-bottom: 1px solid rgba(242, 242, 242, 1);
            display: flex;

            img {
                width: 20px;
                height: 20px;
                margin-right: 12px;
            }

            .banlace_box {
                color: rgba(153, 153, 153, 1);
                font-size: 12px;
            }
        }

        .paypop_checks {
            width: 100%;

            .paypop_checks_box {
                padding: 14px 19px;
                font-size: 16px;
                font-weight: 700;
                color: rgba(0, 0, 0, 1);
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 8px;

                border: 1px solid rgba(230, 230, 230, 1);
                margin-bottom: 14px;

                .checkbox {
                    width: 14px;
                    height: 14px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>